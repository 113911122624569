window.jQuery = $;
window.$ = $;

$(document).ready(function() {
    keycloakLogin();
});


function keycloakLogin() {

    let keycloakPixelURLElement = document.getElementById("keycloakPixelURL");
    let keycloakLoginURLElement = document.getElementById("keycloakLoginURL");

    if (keycloakLoginURLElement == null || keycloakPixelURLElement == null) { // intentional abstract equality
        // if the user is already logged in or the client isn't the agri-check app, then these elements won't exist
        return;
    }

    let keycloakPixelURL = keycloakPixelURLElement.value;
    let keycloakLoginURL = keycloakLoginURLElement.value;

    fetch(keycloakPixelURL, {
        method: 'GET',
        credentials: 'include',
        redirect: 'manual' // we cannot follow the redirect because of CORS. The redirect will be for a resource on a different server:
                           // The initial request goes to keycloak. If the user is already logged in, keycloak will redirect the request
                           // to the supplied redirect URL, which is the login pixel and is located on the PricingService server.
                           // Chrome considers this a "privacy-sensitive context" because the redirect-response belongs to a different server
                           // and thus sets the 'Origin' header of the request that follows the redirect to 'null'.
                           // The only way to comply with CORS would then be to set the CORS Header to 'null' as well,
                           // which would be very insecure. Thus, we do not follow the redirect here and instead detect it.
    })
        .then(response => {
            if (response.type === "opaqueredirect") {
                // Keycloak has redirect the request. This means that the user has to be logged in.
                window.location.href = keycloakLoginURL + "?redirect=" + window.location.href;
            }
        })
        .catch(error => console.log(error));
}