window.jQuery = $;

window.$ = $;

$(document).ready(function() {
  if ($('#articlesResultList').length) {
    prepareFilter(".ps-form__search-filter-opener", ".ps-form");
  }

    function prepareFilter(filterOpenerMatcher, filterFormMatcher) {
        $(document).on("click", filterOpenerMatcher, function() {
            $(filterFormMatcher).slideDown();
            $(this).slideUp();
        });
    }
});
