$(document).ready(function() {
  $('.js-nice-select').niceSelect();
  $("#videoTeaserPlayButton").click(function () {
    let width = $(".bw-video-teaser__content").innerWidth();
    let height = $(".bw-video-teaser__content").innerHeight();
    $(".bw-video-teaser__content").text('');
    let iframe = '<iframe src="https://www.youtube.com/embed/gWZpmrYzPag?autoplay=1" width="' + width + '" height="' + height + '" frameborder="0" allowfullscreen="true" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>'
    $(".bw-video-teaser__content").append(iframe);
  });
});

