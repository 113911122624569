var cartCountdown = function (leftTimeMillis) {

    // Time calculations for minutes and seconds
    var minutes = Math.floor((leftTimeMillis % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((leftTimeMillis % (1000 * 60)) / 1000);
    var minutesString = minutes > 0 ? ('0' + minutes).slice(-2) + ":" : "00:";
    var secondsString = seconds > 0 ? ('0' + seconds).slice(-2) : "00";

    // Display the result
    $('.js-cart-expiration-countdown').html(minutesString + secondsString);
};

$(document).ready(function () {
    if ($('.js-cart-expiration-countdown').length) {
        // get expiration left time
        var leftTimeMillis = $('.js-cart-expiration-countdown').data('expiration-left-time');
        // Update the count down every 1 second
        cartCountdown(leftTimeMillis);
        setInterval(function () {
            leftTimeMillis -= 1000;
            cartCountdown(leftTimeMillis);
            if (leftTimeMillis <= 0) {
                clearInterval(cartCountdown);
            }
        }, 1000);
    }
});