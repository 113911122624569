$(document).ready( function() {
    $(window).resize(headerResize);
    headerResize();
    mobileLoginToggleIcon();
    initNav();
    initMobileNav();
});

function initMobileNav() {
    $(".navigation__mobile-item-link").click(function(event) {
        toggleMobileSubNav(event);
    });
    $("#mobile-menu-icon, #mobile-navigation-overlay").click(function(event) {
        console.log("menu-click happened");
        $(".bw-alertbox--error").hide();
        $("#mobile-navigation-container").slideToggle(function() {
            // Animation complete.
            var navVisible = $("#mobile-navigation-container").is( ":visible" );
            console.log(navVisible);
            if (!navVisible) {
                $(".bw-alertbox--error").show();
            }
        });
        $("#mobile-navigation-overlay").toggle();

    });


    $(".navigation__mobile-sub-nav").each(function(index, element) {
        var urlAttribute = element.getAttribute("data-url");
        var currentLocation = window.location.href;
        if (currentLocation.includes(urlAttribute)) {
            $(element).show();
        }
    });
}

function toggleMobileSubNav(event) {
    $(event.target).toggleClass("is-open");
    var targetId = event.target.getAttribute("data-id");
    var containerId = "mobile-item-container-" + targetId;
    $("[data-id='" + containerId + "']").find(".navigation__mobile-sub-nav").slideToggle();

    var toRemoveContainers = $("[data-id^='mobile-item-container-']").filter(function(index, element) {
        return !$(element).attr("data-id").endsWith(targetId);
    });

    $(toRemoveContainers).find(".navigation__mobile-sub-nav").hide();
    $(toRemoveContainers).find(".navigation__mobile-item-link").removeClass("is-open");
}

function initNav() {
    $(".navigation-item").mouseover(function(e) {
        $(".bw-alertbox--error").hide();
        showSubnav(this);
    });
    $(".navigation-bar").mouseleave(function(e) {
        hideSubnav();
        $(".bw-alertbox--error").show();
    });
}

function showSubnav(element) {
    $(".navigation__sub-nav").hide();
    var id = element.getAttribute("data-id");
    $("#" + id).show();
    $("#navigation-overlay").show();
}
function hideSubnav() {
    $(".navigation__sub-nav").hide();
    $("#navigation-overlay").hide();
}

function headerResize() {
    if ($(window).width() < 768) {
        $(".ps-header-item-loggedin").click(function(e) {
            console.log(".ps-header-item > a - click");
            e.preventDefault();
        });
    }
    else {
        $(".ps-header-item-loggedin-box").hide();
        $(".ps-header-item, div.icon-user").removeClass("open");
    }
}

function mobileLoginToggleIcon() {
    $(".ps-header-item-loggedin").click(function(e){
        if ($(window).width() < 1024) {
            $(".ps-header-item-loggedin-box").fadeToggle("slow", "linear");
            $(".bw-header-item, div.icon-user, .ps-header-item-loggedin_mobile-close-icon, .ps-header-item-loggedin-text").toggleClass("open");
            $(".nav-icon").removeClass("open");
            $(".leftnav").fadeOut("slow", "linear");
        }
        e.stopPropagation();
    });

    $(".ps-header-item-loggedin-box").click(function(e){
        e.stopPropagation();
    });

    $(document).click(function(){
        $(".ps-header-item-loggedin-box").fadeOut("slow", "linear");
        //$(".bw-header-item, div.icon-user").removeClass("open");
    });
}
