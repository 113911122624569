$(document).ready(function() {
    if ($('.ps-breadcrumb__section-link-back').length) {
        pressBrowserBack();
    }

    function pressBrowserBack(){
        $(document).on("click", ".ps-breadcrumb__section-link-back", function () {
            window.history.back();
        });
    }
});
