(function (window, document, $) {

    'use strict';

    window.dataLayer = window.dataLayer || [];

    var gAnalytics = {};

    gAnalytics.init = function () {
        $(document).ready(function () {
            gAnalytics.sendECommerceEvent();
        });
    };

    gAnalytics.sendECommerceEvent = function () {
        var $gAnalyticsEventForm = $('.js-g-analytics-event-Form');

        $gAnalyticsEventForm.on('submit', function (event) {
            let index = $('.js-g-analytics-event-Form').index(this);
            var gaObject = $(this).data('datalayer-object'),
                gaEvent = $(this).data('g-analytics-event'),
                paymentMethod = $(this).data('paymentmethod'),
                formSubmitted = false;
            if (gaObject === undefined || gaEvent === undefined) {
                return;
            }
            if (!gaObject.paymentMethod && paymentMethod) {
                gaObject.paymentMethod = paymentMethod;
            }

            var jsonGA4Object = createGA4DatalayerJson(gaObject, gaEvent, index);
            event.preventDefault();
            pushDataLayerObject(jsonGA4Object, $(this));
            triggerSubmitByTimeout($(this), 1000);

            function triggerSubmitByTimeout($form, timeout) {
                function fn() {
                    if (!formSubmitted) {
                        formSubmitted = true;
                        $form.trigger('dataLayerPushed');
                    }
                }

                setTimeout(fn, timeout || 1000);
                return fn;
            }

            function pushDataLayerObject(jsonObject, $form) {
                if (jsonObject === undefined) {
                    return;
                }
                jsonObject.eventCallback = function () {
                    formSubmitted = true;
                    $form.trigger('dataLayerPushed');
                };
                dataLayer.push(jsonObject);
            }
        });
        $gAnalyticsEventForm.on('dataLayerPushed', function () {
            this.submit();
        });

    };


    function createGA4DatalayerJson(gaObject, gaEvent, index) {
        if (gaEvent === undefined || gaObject === undefined) {
            return false;
        }

        if (gaEvent.name === "addToCart") {
            return {
                'event': 'select_item',
                'category': 'Enhanced eCommerce',
                'action': 'Produkt Klick',
                'label': gaObject["item_id"],
                'ecommerce': {
                    'items': [{
                        'item_name': gaObject["item_name"],
                        'item_id': gaObject["item_id"],
                        'price': gaObject["price"],
                        'item_brand': gaObject["item_brand"],
                        'item_category': gaObject["item_category"],
                        'item_category2': gaObject["item_category2"],
                        'item_category3': gaObject["item_category3"],
                        'item_category4': gaObject["item_category4"],
                        'item_list_name': gaObject["item_list_name"],
                        'index': index + 1,
                        'quantity': gaObject["quantity"],
                        'SAP_sector': gaObject["SAP_sector"],
                        'SAP_product_group': gaObject["SAP_product_group"],
                        'division': gaObject["division"],
                        'SAP_main_group': gaObject["SAP_main_group"]
                    }]
                }
            }
        } else {
            return false;
        }

    }

    gAnalytics.init();

    $(".shop-switcher--item").each(function () {
        $(this).children("a").click(function () {
            let portal = $(this).children("span").first().text();
            let jsonObject = createGA4DatalayerJsonForPortalSwitch(portal);
            if (jsonObject) {
                window.dataLayer.push(jsonObject);
            }
        })
    })

    $(".js-analytics-event-searchfilter").change(function () {
        let eventName = $(this).attr('data-analytics-event');
        let eventValue = $("#" + this.id + " option:selected").text();
        let postcode = $("#energyPricingFormPostcode").val();

        let jsonObject = createGA4DatalayerJsonForSearchFilter(eventName, eventValue, postcode);

        if (jsonObject) {
            window.dataLayer.push(jsonObject);
        }
        this.form.submit();
    })

    $("#energyPricingFormSubmit").click(function () {
        let eventName = 'Preis neu berechnen';
        let amountElem = $("#energyPricingFormQuantity");
        let amount = Number.parseInt($(amountElem).val());
        let amountMin = Number.parseInt($(amountElem).attr("min"));
        let amountMax = Number.parseInt($(amountElem).attr("max"));
        let postcode = $("#energyPricingFormPostcode").val();
        let postcodeValid = !isNaN(postcode) && postcode.length === 5;

        if (postcodeValid && amount <= amountMax && amount >= amountMin) {
            let eventValue = '' + postcode + '|' + amount;
            let jsonObject = createGA4DatalayerJsonForSearchFilter(eventName, eventValue, postcode);
            if (jsonObject) {
                window.dataLayer.push(jsonObject);
            }
            this.form.submit();
        }

    })

    function createGA4DatalayerJsonForSearchFilter(gaEvent, gaEventValue, postcode) {
        if (gaEvent === undefined || gaEventValue === undefined) {
            return false;
        }

        return {
            'event': 'generic_event',
            'category': 'Energie Filter',
            'action': gaEvent,
            'label': gaEventValue,
            'postcode' : postcode
        }
    }

    function createGA4DatalayerJsonForPortalSwitch(portal) {

        return {
            'event': 'portal_switch',
            'category': 'Portal Wechsel',
            'action': portal,
            'label': '-',
            'portal_switch': portal
        }

    }

})(window, document, jQuery);