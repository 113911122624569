$(document).ready(function() {
    footerAlign();
});
$( window ).resize(function() {
    footerAlign();
});

function footerAlign() {
    var footer = $("footer");
    if (footer.position() != null) { // check if null or undefined (abstract equality)
        var docHeight = $(window).height();
        var footerHeight = footer.outerHeight();
        var footerTop = footer.position().top + footerHeight;

        if (footerTop < docHeight) {
            footer.css("margin-top", (docHeight - footerTop) + "px");
        }
    }
}