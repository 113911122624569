window.jQuery = $;
window.$ = $;

$(document).ready(function() {
    if ($('#addresses_form').length) {
        prepareAddressesScript();
        showInformation();
        setDateOfBirth();
        $('#addresses_form input').trigger('input');
        $('#divergent-invoice-addressData').trigger('change');
    }
});

function showInformation() {
    $(".info__text").hide();
    $(document).on("touchstart", "body", function () {
        $(".info__text").hide();
    }).on("touchstart mouseover", ".info__trigger", function (e) {
        e.stopPropagation();
        $(e.target).next(".info__text").show();
    }).on("mouseout", ".info__trigger", function (e) {
        $(e.target).next(".info__text").hide();
    });
}
function prepareAddressesScript() {
    var iconWidth = 35;
    $(document).on("input", ".bw-form__input--clear", function () {
        $(this).parent("div.bw-form__group")[tog(this.value)]("ico-x");
    }).on("input", ".bw-form__input--clear", function () {
        $(this).parent("div.bw-form__group-small")[tog(this.value)]("ico-x");
    }).on("input", ".bw-form__input--clear.bw-form__input--info", function () {
        $(this).parent("div.bw-form__group")[reverseTog(this.value)]("ico-i");
    }).on("input", ".bw-form__input--clear.bw-form__input--info", function () {
        $(this).parent("div.bw-form__group-small")[reverseTog(this.value)]("ico-i");
    }).on("touchstart click", ".ico-x", function (ev) {
        var pWidth = $(this).innerWidth();
        var pOffset = $(this).offset();
        var x = ev.pageX - pOffset.left;
        if (pWidth - iconWidth < x) {
            $(this).children("input").val('').change().blur();
            $(this).removeClass("ico-x");
            if ($(this).children("input").hasClass("bw-form__input--info")) {
                $(this).addClass("ico-i");
            }
        }
    }).on("touchstart", "body", function () {
        $(".bw-form__infotxt").addClass("invisible");
    }).on("touchstart mouseover mousemove", ".ico-i", function (ev) {
        $(".bw-form__infotxt").addClass("invisible");
        var pWidth = $(this).innerWidth();
        var pOffset = $(this).offset();
        var x = ev.pageX - pOffset.left;
        if (pWidth - iconWidth < x) {
            $(this).children(".bw-form__infotxt").removeClass("invisible");
        }
    }).on("mouseout", ".ico-i", function (ev) {
        var pWidth = $(this).innerWidth();
        var pOffset = $(this).offset();
        var x = ev.pageX - pOffset.left;
        if (pWidth - iconWidth < x) {
            $(this).children(".bw-form__infotxt").addClass("invisible");
        }
    }).on("mousemove", ".ico-i", function (ev) {
        var pWidth = $(this).innerWidth();
        var pOffset = $(this).offset();
        var x = ev.pageX - pOffset.left;
        if (pWidth - iconWidth > x) {
            $(this).children(".bw-form__infotxt").addClass("invisible");
        }
    });

    $(".bw-form__input--clear").trigger("input");


    $('#divergent-invoice-addressData').change(function () {
        if (this.checked) {
            $('#invoice_address_whitebox').slideDown();
            $('#invoice_address_whitebox input').attr("required","required");
        } else {
            $('#invoice_address_whitebox').slideUp();
            $('#invoice_address_whitebox input').attr("required",null);
            $('#invoice_address_whitebox input:not([readonly]):not([type="hidden"]):not([type="radio"])').val("");
        }
        $('#addresses_form input').trigger('input');
    });
}

function tog(value) {
    return value ? "addClass" : "removeClass";
}

function reverseTog(value) {
    return value ? "removeClass" : "addClass";
}

function setDateOfBirth() {
    $('.js-personal-data-dateOfBirth').on('change', function () {
        var day = $('#personal_data_dateOfBirth_day').val(),
            month = $('#personal_data_dateOfBirth_month').val(),
            year = $('#personal_data_dateOfBirth_year').val();
        day = (day <= 9) ? ("0" + day).slice(-2) : day;
        month = (month <= 9) ? ("0" + month).slice(-2) : month;
        year = parseInt(year, 10).toString();
        $('#personal_data__dateOfBirth_input').val(year + '-' + month + '-' + day);
    });

    if ($('#personal_data__dateOfBirth_input').hasClass('alert-danger')) {
        $('.js-personal-data-dateOfBirth').addClass('alert-danger');
    }
}